import { Elm } from './Main.elm'
import SoundPlayer from './soundPlayer.js';

const backendUrl = '/backend'
const brightness = localStorage.getItem("brightness") || "";

const app = Elm.Main.init({
  node: document.querySelector('main'),
  flags: { backendUrl, brightness }
})

app.ports.set.subscribe((data) => { localStorage.setItem("brightness", data) });

const soundPlayer = new SoundPlayer();
app.ports.playSound.subscribe(s => {soundPlayer.play(s);});
app.ports.pauseSound.subscribe(() => {soundPlayer.pause();});
app.ports.setVolume.subscribe(volume => {soundPlayer.setVolume(volume);});
soundPlayer.onSoundEnded(() => app.ports.soundEnded.send(""))
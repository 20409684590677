class SoundPlayer {
    constructor() {
        this.currentSound = null;
        this.currentSrcPath = null;
        this.volume = 0.5;
        this.soundEndedCallback = () => {};
    }

    play(sound) {
        if (this.currentSound != null) {
            if(this.currentSound.paused && this.currentSrcPath === sound) {
                this.currentSound.play();
                return;
            }

            this.currentSound.remove();
            this.currentSound.srcObject = null;
            this.currentSound = null;
        }

        this.currentSrcPath = sound;
        let audio = new Audio(sound);
        audio.volume = this.volume;
        audio.onended = () => { this.soundEndedCallback(this.currentSrcPath); };
        audio.play();
        this.currentSound = audio;
    }

    pause() {
        this.currentSound.pause();
    }

    setVolume(volume) {
        this.volume = Math.min(0.0, Math.max(volume, 1.0));
        if (this.currentSound != null)
        {
            this.currentSound.volume = volume;
        }
    }

    onSoundEnded(callback) {
        this.soundEndedCallback = callback;
    }
}

export default SoundPlayer;